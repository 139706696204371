<template>
  <div class="admin">
    <MapDialog title="画布管理" width="600px" :footer="false" @comfirm="comfirm" @cancel="cancel">
      <MapTable :data="data" :column="column" :operations="operations"></MapTable>
    </MapDialog>
  </div>
</template>

<script>
import MapDialog from '../components/dialog';
import MapTable from '../components/editor-table';
export default {
  data() {
    return {
      data: [],
      column: [
        {
          prop: 'name',
          label: '画布名称'
        }
      ],
      operations: {
        width: 100,
        handlers: [
          {
            icon: 'icon-dca-bianji1',
            handler: this.edit
          },
          {
            icon: 'icon-dcshanchu',
            handler: this.delete
          }
        ]
      }
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const { id } = JSON.parse(localStorage.getItem('FactoryMap'));
      const { electronMapList = [] } = await this.$apis.electron.electronList({ factoryId: id });

      this.data = electronMapList.map(item => {
        return {
          ...item,
          edit: false
        };
      });
    },
    async edit(column) {
      if (column.edit) {
        const { fail } = await this.$apis.electron.updateName({ electronMapId: column.electronMapId, name: column.name });

        if (!fail) {
          column.edit = false;
          this.operations.handlers[0].icon = 'icon-dca-bianji1';
        }
      } else {
        column.edit = true;
        this.operations.handlers[0].icon = 'icon-dcicon_save1';
      }
    },
    async delete({ electronMapId }) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { fail } = await this.$apis.electron.del({ electronMapId });

        if (!fail) {
          const { id, mapId } = JSON.parse(localStorage.getItem('FactoryMap'));
          if (mapId === electronMapId) {
            const FactoryMap = {
              id,
              mapId: ''
            };
            localStorage.setItem('FactoryMap', JSON.stringify(FactoryMap));
          }

          this.loadData();
        }
      });
    },
    comfirm() {
      this.cancel();
    },
    cancel() {
      this.$router.back();
    }
  },
  components: {
    MapDialog,
    MapTable
  }
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss';
</style>
